import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { workflow_api } from "../../../API/services/workflow";

const initialState = {
  templateFormData: {
    tasks: [],
  },
  allTemplates: null,
  allJobs: null,
  selectedWorkflowId: null,
  loading: false,
  error: null,
  loadingEdit: false,
  errorEdit: null,
  message: null,
};
2;

//create template
export const createTemplateAPI = createAsyncThunk(
  "create/template",
  async (payload, { rejectWithValue }) => {
    try {
      const { index, job_id, ...updatedPayload } = payload;
      console.log("payload", updatedPayload);

      const token = await getTokenFromSession();
      const response = await axios.post(
        `${workflow_api.createWorkflow.url}`,
        updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//create jobs
export const createJobAPI = createAsyncThunk(
  "create/job",
  async (payload, { rejectWithValue }) => {
    try {
      const { index, task_category_type, ...updatedPayload } = payload;
      console.log("payload", updatedPayload);

      const token = await getTokenFromSession();
      const response = await axios.post(
        `${workflow_api.createWorkflow.url}`,
        updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get all workflow data by type
export const getWorkflowByType = createAsyncThunk(
  "get/getWorkflowByType",
  async (payload, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();

      const response = await axios.post(
        `${workflow_api.getAllWorkflow.url}`,
        {
          job_type: [payload],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      // Handle errors and return the error response
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);



//get all workflow data by id
export const getWorkflowDetailById = createAsyncThunk(
  "get/getWorkflowDetailById",
  async (id, { rejectWithValue }) => {
    console.log("payload__id_98", id);
    try {
      const token = await getTokenFromSession();

      const response = await axios.post(
        `${workflow_api.getAllWorkflowDetail.url}`,
        {
          job_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      // Handle errors and return the error response
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

//edit template
export const updateTemplateAPI = createAsyncThunk(
  "edit/template",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        index,
        asset_pk,
        assigned_to,
        assignee_first_name,
        assignee_last_name,
        c_id,
        createdAt,
        created_by,
        creater_first_name,
        creater_last_name,
        end_time,
        start_time,
        ...updatedPayload
      } = payload;
      console.log("edit_JOB_payload", updatedPayload);

      const token = await getTokenFromSession();
      const response = await axios.put(`${workflow_api.editTemplate.url}`,updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


//edit job
export const updateJobAPI = createAsyncThunk(
  "edit/template",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        index,
        asset_pk,
        //assigned_to,
        assignee_first_name,
        assignee_last_name,
        c_id,
        createdAt,
        created_by,
        creater_first_name,
        creater_last_name,
        //end_time,
        //start_time,
        ...updatedPayload
      } = payload;
      console.log("edit_JOB_payload", updatedPayload);

      const token = await getTokenFromSession();
      const response = await axios.put(`${workflow_api.editTemplate.url}`,updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const getTemplateSlice = createSlice({
  name: "allworkflow",
  initialState,
  reducers: {
    selectedWorkflowId(state, action) {
      console.log("Action Payload:", action.payload);
      state.selectedWorkflowId = action.payload.id;
    },
    createTemplate(state, action) {
      const stateData = JSON.parse(JSON.stringify(state.templateFormData));
      // console.log("action_payload", action.payload);
      state.templateFormData = {
        ...state.templateFormData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTemplateAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTemplateAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createTemplateAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //create job
      .addCase(createJobAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createJobAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //all templates
      .addCase(getWorkflowByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkflowByType.fulfilled, (state, action) => {
        state.loading = false;
        state.allTemplates = action.payload;
      })
      .addCase(getWorkflowByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //workflow details
      .addCase(getWorkflowDetailById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkflowDetailById.fulfilled, (state, action) => {
        state.loading = false;
        state.templateFormData = action.payload;
      })
      .addCase(getWorkflowDetailById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Actions
export const { selectedWorkflowId, createTemplate } = getTemplateSlice.actions;
export default getTemplateSlice.reducer;
