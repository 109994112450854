import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { workflow_api } from "../../../API/services/workflow";

const initialState = {
  taskCategories: [],
  taskEvidences: [],
  jobFormData: {
    tasks: [],
  },
  selectedAction: "",
  selectedTemplate: null,
  allTemplates: null,
  loading: false,
  error: null,
  loadingEdit: false,
  errorEdit: null,
  message: null,
};

//create template
export const createJobAPI = createAsyncThunk("add-job", async (payload, { rejectWithValue }) => {
  try {
    const { index, ...updatedPayload } = payload;
    console.log("payload", updatedPayload);

    const token = await getTokenFromSession();
    const response = await axios.post(`${workflow_api.createWorkflow.url}`, updatedPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//task category api
export const getTaskCategories = createAsyncThunk("view/fetchTaskCategories", async (_, { rejectWithValue }) => {
  try {
    // console.log("gettaskapicall");
    const token = await getTokenFromSession();
    const response = await axios.get(`${workflow_api.getTaskCategories.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//task evidence api
export const getTaskEvidences = createAsyncThunk("view/fetchTaskEvidences", async (_, { rejectWithValue }) => {
  try {
    // console.log("gettaskapievidences");
    const token = await getTokenFromSession();
    const response = await axios.get(`${workflow_api.getTaskEvidences.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getJobSlice = createSlice({
  name: "allworkflow",
  initialState,
  reducers: {
    selectWorkflow(state, action) {
      console.log("Action Payload:", action.payload);
    },
    createJobs(state, action) {
      const stateData = JSON.parse(JSON.stringify(state.jobFormData));
      console.log("action_payload", action.payload);
      state.jobFormData = {
        ...state.jobFormData,
        ...action.payload,
      };
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createJobAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(createJobAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //task category
      .addCase(getTaskCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaskCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.taskCategories = action.payload;
      })
      .addCase(getTaskCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //task evidences
      .addCase(getTaskEvidences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTaskEvidences.fulfilled, (state, action) => {
        state.loading = false;
        state.taskEvidences = action.payload;
      })
      .addCase(getTaskEvidences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Actions
export const { selectWorkflow, createJobs } = getJobSlice.actions;
export default getJobSlice.reducer;
