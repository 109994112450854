import { createSlice, createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { login_api } from "../../../API/services/login";
export const changePassword = createAsyncThunk(
    'user/change-password',
    async({old_pwd,new_pwd}, {rejectWithValue}) =>{
        try{
               console.log("Changepassowrdslice")
               console.log("old_pwd", old_pwd);
               console.log("mew_pswd", new_pwd)
               const token = await getTokenFromSession();
               console.log(token)
               const response = await axios.patch(login_api.updatePassword.url,
                  {
                    old_pwd,
                    new_pwd
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  }
               
               );
               return response.data;
          }catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const changePasswordSlice = createSlice ({
    name :"changePassword",
    initialState :{
        loading: false,
        success: false,
        error: null
    },
    reducers:{

    },

    extraReducers: (builder) => {
        builder
          .addCase(changePassword.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(changePassword.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = true;
            
          })
          .addCase(changePassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
          });
      }
    });
    
    export default changePasswordSlice.reducer;