let backend_domain = window._env_.REACT_APP_API_URL
let api_version = window._env_.REACT_APP_API_VERSION

//admin api's
export const admin_api = {
    getRoles: {
        method: "GET",
        url: `${backend_domain}${api_version}/getRoles`,
        headers: { 'Content-Type': 'application/json' }
    },
    createUser: {
        method: "POST",
        url: `${backend_domain}${api_version}/create-user`,
        headers: { 'Content-Type': 'application/json' }
    },
    csvUpload: {
        method: "POST",
        url: `${backend_domain}${api_version}/import-users`,
        headers: { 'Content-Type': 'multipart/form-data' }
    },
    getAllUsers: {
        method: "GET",
        url: `${backend_domain}${api_version}/get-all-users`,
        headers: { 'Content-Type': 'application/json' }
    },
    getUser: {
        method: "GET",
        url: `${backend_domain}${api_version}/get-user`,
        headers: { 'Content-Type': 'application/json' }
    },
    updateUser: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/update-user`,
        headers: { 'Content-Type': 'application/json' }
    },
    updateUserSetting: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/update-user-settings`,
        headers: { 'Content-Type': 'application/json' }
    },
    updateProfilePic: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/update-user-profile`,
        headers: { 'Content-Type': 'application/json' }
    },
    deleteUser: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/delete-user`,
        headers: { 'Content-Type': 'application/json' }
    },

}